import styled from "@emotion/styled"
import { graphql } from "gatsby"
import React from "react"
import { BlogPostExcerpt } from "../components/Blog/BlogPostExcerpt"
import Container from "../components/container"
import Layout from "../components/layout"
import SEO from "../components/seo"

const Styles = styled.div`
  padding-top: 40px;
  color: #fff;

  .gatsby-image-wrapper {
    max-height: 60vh;
    margin-bottom: 12px;
  }
`

const ImgWrapper = styled.div`
  width: 80%;
  margin-left: 20px;
  @media (min-width: 768px) {
    width: 50%;
  }
`

const NewsPage = props => {
  const posts = props.data?.allContentfulNewsPost?.edges ?? []

  return (
    <Layout>
      <SEO title="News" />
      <Styles>
        <Container>
          <div
            className="title"
            style={{ fontSize: "2.5em", marginLeft: 20, color: "#fff" }}
          >
            News
          </div>
          {posts.map(post => (
            <BlogPostExcerpt key={post.node.id} post={post.node} />
          ))}
        </Container>
      </Styles>
    </Layout>
  )
}

export const query = graphql`
  query {
    allContentfulNewsPost(sort: { order: DESC, fields: createdAt }) {
      totalCount
      edges {
        node {
          id
          slug
          title
          subhead
          excerpt {
            raw
          }
          excerptImage {
            description
            fluid(maxWidth: 1200) {
              ...GatsbyContentfulFluid
            }
          }
          excerptImageAlignment
          heroImage {
            description
            fluid(maxWidth: 1200) {
              ...GatsbyContentfulFluid
            }
          }
        }
      }
    }
  }
`
export default NewsPage
