import styled from "@emotion/styled"
import { Link } from "gatsby"
import React from "react"
import { Rtf } from "./Rtf"

const Container = styled.div`
  margin-bottom: 48px;

  .post-content {
    margin: 0 20px;
  }

  .title {
    color: #fff;
    font-family: Barlow Condensed;
    text-transform: capitalize;
  }

  .subtitle {
    text-transform: capitalize;
    color: #fff;
  }

  p > strong {
    color: #fff;
  }
`

const ImgWrapper = styled.div`
  display: flex;
  justify-content: ${props =>
    props.alignment !== "Full" ? props.alignment.toLowerCase() : "center"};
  padding: 20px;
  width: 100%;
  & > img {
    width: ${props => (props.alignment === "Full" ? "100%" : "auto")};
    max-height: 340px;
    ${props => (props.alignment === "Full" ? `object-fit: cover;` : "")}
  }
`

export const BlogPostExcerpt = props => {
  const { post } = props
  const {
    slug,
    title,
    subhead,
    excerpt,
    excerptImage,
    excerptImageAlignment,
    heroImage,
  } = post

  const image = excerptImage ?? heroImage
  /**
   * Possible value here are 'Left', 'Right', 'Center', 'Full'.
   * This is to make it nice for the editors. We need to translate
   * this into values usable by CSS. We'll default to 'center'.
   */
  const imageAlignment =
    excerptImageAlignment === "Left"
      ? "flex-start"
      : excerptImageAlignment === "Right"
      ? "flex-end"
      : excerptImageAlignment ?? "center"

  return (
    <Container>
      {image && (
        <ImgWrapper alignment={imageAlignment}>
          <img srcSet={image.fluid.srcSet} alt={image.description} />
        </ImgWrapper>
      )}
      <div className="post-content">
        <div className="title">{title}</div>
        <div className="subtitle">{subhead}</div>
        <Rtf data={excerpt} />
        <Link to={`/news/${slug}`} style={{ color: "#fa4616" }}>
          Read more...
        </Link>
      </div>
    </Container>
  )
}
